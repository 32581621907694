<template>
    <div>
        <vue-title title="Договора | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Договора</h1>
            </div>
            <div class="row">
                <div class="col-12">
                    <div v-if="!contracts">
                        <DxLoadIndicator
                                id="medium-indicator"
                                :height="20"
                                :width="20"
                        />
                        Загрузка
                    </div>
                    <div v-if="contracts && contracts.length > 0" class="accordion" id="accordionContracts">
                        <div class="card" v-for="contract in contracts" :key="contract.contractId">
                            <div class="card-header" :id="'heading-'+contract.contractId">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block d-flex flex-column flex-md-row justify-content-start justify-content-md-between" @click="openContract(contract.contractId)" type="button" data-toggle="collapse" :data-target="'#collapse-'+contract.contractId" aria-expanded="true" :aria-controls="'collapse'+contract.contractId">
                                        <strong><i class="fas fa-chevron-right mr-2" :id="'contractChevron'+contract.contractId"></i>{{contract.contractCaption}}</strong> <div class="d-inline-block" v-html="getContractStatusName(contract.status)"></div>
                                    </button>
                                </h2>
                            </div>

                            <div :id="'collapse-'+contract.contractId" class="collapse" :aria-labelledby="'heading-'+contract.contractId" data-parent="#accordionContracts">
                                <div class="card-body">
                                    <ContractDetail :key="'detail-'+contract.contractId" v-if="openContractId == contract.contractId" :contractId="contract.contractId" :Agent="Agent" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="contracts && contracts.length == 0">
                        <h5 class="my-5">На данный момент договоров нет.</h5>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import jQuery from 'jquery';
    let $ = jQuery;
    import headerRoutes from './header-routes';
    import {mapGetters} from 'vuex';
    import localStore from './store';
    import {Header, Footer} from '../../shared/components';
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';

    import ContractDetail from './contracts/ContractDetail';

    export default {
        name: 'ProfileContracts',
        components: {
            Header,
            Footer,
            DxLoadIndicator,
            ContractDetail
        },
        data() {
            return {
                headerRoutes,
                contracts: null,
                openContractId: "",
                lastOpenContractId: ""
            }
        },
        mounted() {
            this.getContracts();
        },
        methods: {
            getContracts() {
                this.$store.dispatch('loading', true).then(() => {
                    localStore.dispatch('getContracts', {tokenId: this.Agent.tokenId}).then(() => {
                        this.contracts = localStore.state.contracts;

                        this.$store.dispatch('loading', false);
                    })
                });
            },
            getContractStatusName(status) {
                let statuses = {
                    'csNone': '<span class="badge badge-dark font-12">None</span>',
                    'csProcessing': '<span class="badge badge-warning font-12">В обработке</span>',
                    'csSigned': '<span class="badge badge-success font-12">Активен</span>',
                    'csSuspended': '<span class="badge badge-danger font-12">Приостановлен</span>',
                    'csSuppliment': '<span class="badge badge-warning font-12">Доп. соглашение</span>',
                    'csTerminated': '<span class="badge badge-danger font-12">Расторгнут</span>',
                };

                return statuses[status];
            },
            openContract(contractId) {
                this.changeContractChevron(contractId);
                this.openContractId = contractId;
            },
            changeContractChevron(contractId) {

                if($('#contractChevron'+contractId).hasClass('fa-chevron-right')) {
                    $('#contractChevron'+contractId).removeClass('fa-chevron-right');
                    $('#contractChevron'+contractId).addClass('fa-chevron-down');
                } else {
                    $('#contractChevron'+contractId).removeClass('fa-chevron-down');
                    $('#contractChevron'+contractId).addClass('fa-chevron-right');
                }

                if(this.lastOpenContractId != "" && this.lastOpenContractId != contractId) {

                    if($('#contractChevron'+this.lastOpenContractId).hasClass('fa-chevron-down')) {
                        $('#contractChevron'+this.lastOpenContractId).removeClass('fa-chevron-down');
                        $('#contractChevron'+this.lastOpenContractId).addClass('fa-chevron-right');

                    }
                }

                this.lastOpenContractId = contractId;
            },
        },
        computed: {
            ...mapGetters([
                'isLoading',
                'Agent'
            ])
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
